body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tags {
  margin-right: 5px;
  display: inline-block;
  padding: 2px 5px;
  text-align: center;
  border-radius: 160px;
  font-size: 10px

  
}

.tags.isAutoPay {
  color: #fff;
  background-color: #28a745;
}

.tags.final_bill {
  color: #fff;
  background-color: #6c757d;
}

.tags.past_due {
  color: #fff;
  background-color: #dc3545;
}

.tags.disconnectionRisk {
  color: #212529;
  background-color: #ffc107;
}